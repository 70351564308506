import React from 'react';
import HomePage from '../pagesComponents/HomePage';

export default function Dg24PageEs({path}) {
  if (typeof window !== "undefined" && window.localStorage)
    window.localStorage.setItem("uiLang", "es");

  return (
    <HomePage appContextValue={{language: "es", digistore24: true, path}} />
  );
}
